.materialert{
    position: relative;
    min-width: 150px;
    padding: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: all 0.1s linear;
    webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
}
.materialert .material-icons{
    margin-right: 10px;
}
.materialert .close-alert{
    -webkit-appearance: none;
    border: 0;
    cursor: pointer;
    color: inherit;
    background: 0 0;
    font-size: 22px;
    line-height: 1;
    font-weight: bold;
    text-shadow: 0 1px 0 rgba(255, 255, 255, .7);
    filter: alpha(opacity=40);
    margin-bottom: -5px;
    position: absolute;
    top: 16px;
    right: 5px;
}
.materialert.info{
    background-color: #039be5;
    color: #fff;
}
.materialert.success{
    background-color: #43a047;
    color: #fff;
}
.materialert.error{    
    background-color: #F44336;
    color: #fff;
    box-shadow: 0 1px 0 0 #F44336;
}
.materialert.danger{
    background-color: #c62828;
    color: #fff;
}
.materialert.warning{
    background-color: #fbc02d;
    color: #fff;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}